import Checkbox from '@sats-group/icons/32/checkbox';
import Error from '@sats-group/icons/32/error';
import confetti from 'canvas-confetti';
import React, { useEffect, useRef } from 'react';
import Banner from 'sats-ui-lib/react/banner';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import Logo from '../logo/logo';
import type { FlowTerminusLayout as Props } from './flow-terminus-layout.types';
import ContentContainer from '../content-container/content-container';

const options: Record<NonNullable<Props['mood']>, confetti.Options[]> = {
  celebratory: [
    {
      origin: { y: 0.7 },
      particleCount: 100,
      spread: 26,
      startVelocity: 55,
    },
    {
      origin: { y: 0.7 },
      particleCount: 80,
      spread: 60,
    },
    {
      decay: 0.91,
      origin: { y: 0.7 },
      particleCount: 140,
      scalar: 0.8,
      spread: 100,
    },
    {
      decay: 0.92,
      origin: { y: 0.7 },
      particleCount: 40,
      scalar: 1.2,
      spread: 120,
      startVelocity: 25,
    },
    {
      origin: { y: 0.7 },
      particleCount: 40,
      spread: 120,
      startVelocity: 45,
    },
  ],
};

const FlowTerminusLayout: React.FC<React.PropsWithChildren<Props>> = ({
  actions,
  children,
  indicator,
  logo,
  messages,
  mood,
  title,
}) => {
  const animationCanvas = useRef(null);
  const localConfetti = useRef<ReturnType<(typeof confetti)['create']>>(null);

  useEffect(() => {
    if (!animationCanvas.current) {
      return;
    }

    if (!mood) {
      return;
    }

    if (!localConfetti.current) {
      // NOTE: The type is wrong.
      // `.current` is not read-only.
      //
      // > This value is intentionally mutable,
      // > meaning you can both read and write to it.
      // > It’s like a secret pocket of your component that React doesn’t track.
      //
      // - https://react.dev/learn/referencing-values-with-refs
      //
      // @ts-expect-error
      localConfetti.current = confetti.create(animationCanvas.current, {
        disableForReducedMotion: true,
        resize: true,
        useWorker: true,
      });
    }

    options[mood].forEach(option => {
      if (localConfetti.current) {
        localConfetti.current(option);
      }
    });

    return () => {
      if (localConfetti.current) {
        localConfetti.current.reset();
      }
    };
  }, []);

  return (
    <div className="flow-terminus-layout">
      <ContentContainer>
        <div className="flow-terminus-layout__content">
          <header className="flow-terminus-layout__logo">
            <Logo {...logo} size="large" />
          </header>
          <main className="flow-terminus-layout__card">
            <aside
              className="flow-terminus-layout__animation"
              role="presentation"
            >
              <canvas ref={animationCanvas} />
            </aside>
            <div className="flow-terminus-layout__title">
              {indicator ? (
                <div
                  className={`flow-terminus-layout__indicator flow-terminus-layout__indicator--${indicator}`}
                >
                  {indicator === 'error' ? <Error /> : null}
                  {indicator === 'success' ? <Checkbox /> : null}
                </div>
              ) : null}
              <Text
                elementName="h1"
                size={Text.sizes.headline2}
                theme={Text.themes.headline}
                italic
              >
                {title}
              </Text>
            </div>
            <div className="flow-terminus-layout__messages">
              {messages.primary.map(entry => (
                <div key={entry.text}>
                  <Banner {...entry} />
                </div>
              ))}
            </div>
            <div className="flow-terminus-layout__children">{children}</div>
            <div className="flow-terminus-layout__messages">
              {messages.secondary.map(entry => (
                <div key={entry.text}>
                  <Banner {...entry} />
                </div>
              ))}
            </div>
            <div className="flow-terminus-layout__actions">
              {actions.map((entry, index) => (
                <div key={entry.href}>
                  <LinkButton
                    {...entry}
                    variant={index ? LinkButton.variants.secondary : undefined}
                  />
                </div>
              ))}
            </div>
          </main>
        </div>
      </ContentContainer>
    </div>
  );
};

export default FlowTerminusLayout;
