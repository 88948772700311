import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { CorporateCodeError as Props } from './corporate-code-error.types';

const CorporateCodeError: React.FunctionComponent<Props> = ({ layout }) => (
  <FlowTerminusLayout {...layout}>
    <div className="corporate-code-error"></div>
  </FlowTerminusLayout>
);

export default CorporateCodeError;
