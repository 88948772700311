import SvgCreditcard from '@sats-group/icons/24/creditcard';
import React from 'react';
import Text from 'sats-ui-lib/react/text';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { CorporateCodeConfirmation as Props } from './corporate-code-confirmation.types';

const CorporateCodeConfirmation: React.FunctionComponent<Props> = ({
  data,
  layout,
  message,
}) => (
  <FlowTerminusLayout {...layout}>
    <div className="corporate-code-confirmation">
      <div className="corporate-code-confirmation__message">
        <div>
          <SvgCreditcard />
        </div>
        <Text>{message}</Text>
      </div>
      <table className="corporate-code-confirmation__data">
        <tbody>
          {data.map(([key, value]) => (
            <tr key={key}>
              <Text elementName="th" theme={Text.themes.emphasis}>
                {key}
              </Text>
              <Text elementName="td">{value}</Text>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </FlowTerminusLayout>
);

export default CorporateCodeConfirmation;
