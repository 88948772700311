import type { FC } from 'react';
import AboutSatsPage from '../client/pages/about-sats-page/about-sats-page';
import ArticlePage from '../client/pages/article-page/article-page';
import ArticlesCategoryPage from '../client/pages/articles-category-page/articles-category-page';
import ArticlesSubcategoryPage from '../client/pages/articles-subcategory-page/articles-subcategory-page';
import AutogiroAccountsPage from '../client/pages/autogiro-accounts-page/autogiro-accounts-page';
import AutogiroBanksPage from '../client/pages/autogiro-banks-page/autogiro-banks-page';
import AutomaticLogoutPage from '../client/pages/automatic-logout-page/automatic-logout-page';
import AvtaleGiroInitiatePage from '../client/pages/avtale-giro-initiate-page/avtale-giro-initiate-page';
import BookingRestrictions from '../client/pages/booking-restrictions/booking-restrictions';
import BootCampConceptPage from '../client/pages/boot-camp-concept-page/boot-camp-concept-page';
import BootCampEventPage from '../client/pages/boot-camp-event-page/boot-camp-event-page';
import BootCampLandingPage from '../client/pages/boot-camp-landing-page/boot-camp-landing-page';
import BsAutomatiskBetalingPage from '../client/pages/bs-automatisk-betaling-page/bs-automatisk-betaling-page';
import CancelMembershipLandingPage from '../client/pages/cancel-membership-landing-page/cancel-membership-landing-page';
import CancelMembershipPage from '../client/pages/cancel-membership-page/cancel-membership-page';
import CancelMembershipProvideReasonPage from '../client/pages/cancel-membership-provide-reason-page/cancel-membership-provide-reason-page';
import CancellationAlternativeConfirmationPage from '../client/pages/cancellation-alternative-confirmation-page/cancellation-alternative-confirmation-page';
import CancellationAlternativeFormPage from '../client/pages/cancellation-alternative-form-page/cancellation-alternative-form-page';
import CancellationAlternativesPage from '../client/pages/cancellation-alternatives-page/cancellation-alternatives-page';
import ChangeHomeClubConfirmationPage from '../client/pages/change-home-club-confirmation-page/change-home-club-confirmation-page';
import ChangeHomeClubOverviewPage from '../client/pages/change-home-club-overview-page/change-home-club-overview-page';
import ChangeHomeClubPreviewPage from '../client/pages/change-home-club-preview-page/change-home-club-preview-page';
import ChangeMembershipConfirmPage from '../client/pages/change-membership-confirm-page/change-membership-confirm-page';
import ChangeMembershipOptionPage from '../client/pages/change-membership-option-page/change-membership-option-page';
import ChangeMembershipOverviewPage from '../client/pages/change-membership-overview-page/change-membership-overview-page';
import CheckoutConfirmationPage from '../client/pages/checkout-confirmation-page/checkout-confirmation-page';
import ClubPage from '../client/pages/club-page/club-page';
import ClubsOverviewPage from '../client/pages/clubs-overview-page/clubs-overview-page';
import ClubsRegionOverviewPage from '../client/pages/clubs-region-overview-page/clubs-region-overview-page';
import ContactFormStep from '../client/pages/contact-form-step/contact-form-step';
import ContentBlockPreview from '../client/pages/content-block-preview/content-block-preview';
import CorporateArticlePage from '../client/pages/corporate-article-page/corporate-article-page';
import CorporateCodeConfirmation from '../client/pages/corporate-code-confirmation/corporate-code-confirmation';
import CorporateCodeError from '../client/pages/corporate-code-error/corporate-code-error';
import CorporateCodeInput from '../client/pages/corporate-code-input/corporate-code-input';
import CorporateCodeOverview from '../client/pages/corporate-code-overview/corporate-code-overview';
import CorporateCodePreview from '../client/pages/corporate-code-preview/corporate-code-preview';
import CorporateMembershipPage from '../client/pages/corporate-membership-page/corporate-membership-page';
import ErrorPage from '../client/pages/error-page/error-page';
import ExercisePage from '../client/pages/exercise-page/exercise-page';
import FreezeConfirmationPage from '../client/pages/freeze-confirmation-page/freeze-confirmation-page';
import FreezeMotivationsPage from '../client/pages/freeze-motivations-page/freeze-motivations-page';
import FreezeProvideReasonPage from '../client/pages/freeze-provide-reason-page/freeze-provide-reason-page';
import FreezeSelectLengthPage from '../client/pages/freeze-select-length-page/freeze-select-length-page';
import FreezeSelectReasonPage from '../client/pages/freeze-select-reason-page/freeze-select-reason-page';
import GroupExerciseBookingPage from '../client/pages/group-exercise-booking-page/group-exercise-booking-page';
import GroupExercisePage from '../client/pages/group-exercise-page/group-exercise-page';
import HeroPreview from '../client/pages/hero-preview/hero-preview';
import InspirationalArticlePage from '../client/pages/inspirational-article-page/inspirational-article-page';
import LandingPage from '../client/pages/landing-page/landing-page';
import LeadsFormConfirmationPage from '../client/pages/leads-form-confirmation-page/leads-form-confirmation-page';
import LeadsFormPage from '../client/pages/leads-form-page/leads-form-page';
import LinkBoxListPage from '../client/pages/link-box-list-page/link-box-list-page';
import ListPage from '../client/pages/list-page/list-page';
import ListPreview from '../client/pages/list-preview/list-preview';
import LoginPage from '../client/pages/login-page/login-page';
import MagazinePage from '../client/pages/magazine-page/magazine-page';
import MarketingLandingPage from '../client/pages/marketing-landing-page/marketing-landing-page';
import MarketingSubpage from '../client/pages/marketing-subpage/marketing-subpage';
import MemberCareArticlePage from '../client/pages/member-care-article-page/member-care-article-page';
import MemberCareCategoryPage from '../client/pages/member-care-category-page/member-care-category-page';
import MemberCareConfirmationPage from '../client/pages/member-care-confirmation-page/member-care-confirmation-page';
import MemberCarePage from '../client/pages/member-care-page/member-care-page';
import MemberCareSubcategoryPage from '../client/pages/member-care-subcategory-page/member-care-subcategory-page';
import MyCommunicationSettingsPage from '../client/pages/my-communication-settings-page/my-communication-settings-page';
import MyOtherMembersPage from '../client/pages/my-other-members-page/my-other-members-page';
import MyPage from '../client/pages/my-page/my-page';
import MyPagePayments from '../client/pages/my-page-payments/my-page-payments';
import MyPaymentAddCardPage from '../client/pages/my-payment-add-card-page/my-payment-add-card-page';
import MyPaymentMethodsPage from '../client/pages/my-payment-methods-page/my-payment-methods-page';
import MyProfileEditPage from '../client/pages/my-profile-edit-page/my-profile-edit-page';
import MyProfilePage from '../client/pages/my-profile-page/my-profile-page';
import MyUpcomingTrainingPage from '../client/pages/my-upcoming-training-page/my-upcoming-training-page';
import NotFoundPage from '../client/pages/not-found-page/not-found-page';
import PayDebt from '../client/pages/pay-debt/pay-debt';
import PayDebtConfirmation from '../client/pages/pay-debt-confirmation/pay-debt-confirmation';
import PayDebtError from '../client/pages/pay-debt-error/pay-debt-error';
import PaymentPage from '../client/pages/payment-page/payment-page';
import PersonalTrainerLandingPage from '../client/pages/personal-trainer-landing-page/personal-trainer-landing-page';
import PersonalTrainerSelectSessionPage from '../client/pages/personal-trainer-select-session-page/personal-trainer-select-session-page';
import PersonalTrainerSessionPaymentPage from '../client/pages/personal-trainer-session-payment-page/personal-trainer-session-payment-page';
import PersonalTrainerSessionShopConfirmationPage from '../client/pages/personal-trainer-session-shop-confirmation-page/personal-trainer-session-shop-confirmation-page';
import PersonalTrainerSessionShopErrorPage from '../client/pages/personal-trainer-session-shop-error-page/personal-trainer-session-shop-error-page';
import PredefinedMembershipPage from '../client/pages/predefined-membership-page/predefined-membership-page';
import ProfileFilterPage from '../client/pages/profile-filter-page/profile-filter-page';
import ProfilePage from '../client/pages/profile-page/profile-page';
import ProfilingQuestionnaireConfirmationPage from '../client/pages/profiling-questionnaire-confirmation-page/profiling-questionnaire-confirmation-page';
import ProfilingQuestionnairePage from '../client/pages/profiling-questionnaire-page/profiling-questionnaire-page';
import PtShop from '../client/pages/pt-shop/pt-shop';
import PtShopConfirmation from '../client/pages/pt-shop-confirmation/pt-shop-confirmation';
import PtShopError from '../client/pages/pt-shop-error/pt-shop-error';
import PtShopPay from '../client/pages/pt-shop-pay/pt-shop-pay';
import RegistrationPage from '../client/pages/registration-page/registration-page';
import ResetPasswordPage from '../client/pages/reset-password-page/reset-password-page';
import RewardsPage from '../client/pages/rewards-page/rewards-page';
import SelectAccessLevelPage from '../client/pages/select-access-level-page/select-access-level-page';
import SelectClubPage from '../client/pages/select-club-page/select-club-page';
import SelectMemberTypePage from '../client/pages/select-member-type-page/select-member-type-page';
import SelectMembershipPage from '../client/pages/select-membership-page/select-membership-page';
import SetPasswordPage from '../client/pages/set-password-page/set-password-page';
import TermsAndConditionsListPage from '../client/pages/terms-and-conditions-list-page/terms-and-conditions-list-page';
import WaitingArea from '../client/pages/waiting-area/waiting-area';
import WebAdmin from '../client/pages/web-admin/web-admin';
import WebAdminContentTypes from '../client/pages/web-admin-content-types/web-admin-content-types';
import WebAdminData from '../client/pages/web-admin-data/web-admin-data';
import WebAdminLogin from '../client/pages/web-admin-login/web-admin-login';
import WebAdminSettings from '../client/pages/web-admin-settings/web-admin-settings';
import WebAdminSitemap from '../client/pages/web-admin-sitemap/web-admin-sitemap';

/** This object is automatically generated. */
export const pageComponents: Record<string, FC> = {
  AboutSatsPage,
  ArticlePage,
  ArticlesCategoryPage,
  ArticlesSubcategoryPage,
  AutogiroAccountsPage,
  AutogiroBanksPage,
  AutomaticLogoutPage,
  AvtaleGiroInitiatePage,
  BookingRestrictions,
  BootCampConceptPage,
  BootCampEventPage,
  BootCampLandingPage,
  BsAutomatiskBetalingPage,
  CancelMembershipLandingPage,
  CancelMembershipPage,
  CancelMembershipProvideReasonPage,
  CancellationAlternativeConfirmationPage,
  CancellationAlternativeFormPage,
  CancellationAlternativesPage,
  ChangeHomeClubConfirmationPage,
  ChangeHomeClubOverviewPage,
  ChangeHomeClubPreviewPage,
  ChangeMembershipConfirmPage,
  ChangeMembershipOptionPage,
  ChangeMembershipOverviewPage,
  CheckoutConfirmationPage,
  ClubPage,
  ClubsOverviewPage,
  ClubsRegionOverviewPage,
  ContactFormStep,
  ContentBlockPreview,
  CorporateArticlePage,
  CorporateCodeConfirmation,
  CorporateCodeError,
  CorporateCodeInput,
  CorporateCodeOverview,
  CorporateCodePreview,
  CorporateMembershipPage,
  ErrorPage,
  ExercisePage,
  FreezeConfirmationPage,
  FreezeMotivationsPage,
  FreezeProvideReasonPage,
  FreezeSelectLengthPage,
  FreezeSelectReasonPage,
  GroupExerciseBookingPage,
  GroupExercisePage,
  HeroPreview,
  InspirationalArticlePage,
  LandingPage,
  LeadsFormConfirmationPage,
  LeadsFormPage,
  LinkBoxListPage,
  ListPage,
  ListPreview,
  LoginPage,
  MagazinePage,
  MarketingLandingPage,
  MarketingSubpage,
  MemberCareArticlePage,
  MemberCareCategoryPage,
  MemberCareConfirmationPage,
  MemberCarePage,
  MemberCareSubcategoryPage,
  MyCommunicationSettingsPage,
  MyOtherMembersPage,
  MyPage,
  MyPagePayments,
  MyPaymentAddCardPage,
  MyPaymentMethodsPage,
  MyProfileEditPage,
  MyProfilePage,
  MyUpcomingTrainingPage,
  NotFoundPage,
  PayDebt,
  PayDebtConfirmation,
  PayDebtError,
  PaymentPage,
  PersonalTrainerLandingPage,
  PersonalTrainerSelectSessionPage,
  PersonalTrainerSessionPaymentPage,
  PersonalTrainerSessionShopConfirmationPage,
  PersonalTrainerSessionShopErrorPage,
  PredefinedMembershipPage,
  ProfileFilterPage,
  ProfilePage,
  ProfilingQuestionnaireConfirmationPage,
  ProfilingQuestionnairePage,
  PtShop,
  PtShopConfirmation,
  PtShopError,
  PtShopPay,
  RegistrationPage,
  ResetPasswordPage,
  RewardsPage,
  SelectAccessLevelPage,
  SelectClubPage,
  SelectMemberTypePage,
  SelectMembershipPage,
  SetPasswordPage,
  TermsAndConditionsListPage,
  WaitingArea,
  WebAdmin,
  WebAdminContentTypes,
  WebAdminData,
  WebAdminLogin,
  WebAdminSettings,
  WebAdminSitemap,
};
